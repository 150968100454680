<template>
    <div class="grid grid-cols-12 ml-30 mr-40 mt-30 gap-x-30">
        <!-- Upper row with general cases statistics and buttons -->
        <div class="col-span-12 md:col-span-6 xl:col-span-3 py-20 flex justify-center items-center bg-white rounded-lg shadow mb-30">
            <p
                v-if="inProgressCases"
                class="text-6xl font-titillium-bold font-bold text-indigo-400 pr-20"
            >
                {{inProgressCases}}
            </p>
            <p
                v-else
                class="text-6xl font-titillium-bold font-bold text-indigo-400 pr-20"
            >
                0
            </p>
            <div v-if="inProgressCases">
                <p
                    v-if="inProgressCases > 1"
                    class="text-lg2 font-titillium-bold font-bold text-gray-600"
                >
                    {{$t('dashboardInProgressCases')}}
                </p>
                <p
                    v-else
                    class="text-lg2 font-titillium-bold font-bold text-gray-600"
                >
                    {{$t('dashboardInProgressCase')}}
                </p>
            </div>
            <p
                v-else
                class="text-lg2 font-titillium-bold font-bold text-gray-600"
            >
                {{$t('dashboardInProgressCases')}}
            </p>
        </div>
        <div class="col-span-12 md:col-span-6 xl:col-span-3 py-20 flex justify-center items-center bg-white rounded-lg shadow mb-30">
            <p
                v-if="registeredCases"
                class="text-6xl font-titillium-bold font-bold text-indigo-400 pr-20"
            >
                {{registeredCases}}
            </p>
            <p
                v-else
                class="text-6xl font-titillium-bold font-bold text-indigo-400 pr-20"
            >
                0
            </p>
            <div v-if="registeredCases">
                <p
                    v-if="registeredCases > 1"
                    class="text-lg2 font-titillium-bold font-bold text-gray-600"
                >
                    {{$t('dashboardRegisteredCases')}}
                </p>
                <p
                    v-else
                    class="text-lg2 font-titillium-bold font-bold text-gray-600"
                >
                    {{$t('dashboardRegisteredCase')}}
                </p>
            </div>
            <p
                v-else
                class="text-lg2 font-titillium-bold font-bold text-gray-600"
            >
                {{$t('dashboardRegisteredCases')}}
            </p>
        </div>
        <div class="col-span-12 md:col-span-6 xl:col-span-3 py-20 flex justify-center items-center bg-white rounded-lg shadow mb-30">
            <p
                v-if="reportedCases"
                class="text-6xl font-titillium-bold font-bold text-indigo-400 pr-20"
            >
                {{reportedCases}}
            </p>
            <p
                v-else
                class="text-6xl font-titillium-bold font-bold text-indigo-400 pr-20"
            >
                0
            </p>
            <div v-if="reportedCases">
                <p
                    v-if="reportedCases > 1"
                    class="text-lg2 font-titillium-bold font-bold text-gray-600"
                >
                    {{$t('dashboardReportedCases')}}
                </p>
                <p
                    v-else
                    class="text-lg2 font-titillium-bold font-bold text-gray-600"
                >
                    {{$t('dashboardReportedCase')}}
                </p>
            </div>
            <p
                v-else
                class="text-lg2 font-titillium-bold font-bold text-gray-600"
            >
                {{$t('dashboardReportedCases')}}
            </p>
        </div>
        <div
            v-if="userTypeManager"
            class="col-span-12 md:col-span-6 order-first xl:col-span-3 xl:order-none mb-30"
        >
            <router-link
                class="mb-10 button bg-purple-500 w-full text-white hover:bg-purple-600 flex items-center justify-center text-lg"
                :to='{name:"CaseAdd"}'
            >
                {{ $t('dashboardNewCaseButtonText') }}
            </router-link>
            <router-link
                class="button bg-purple-500 w-full text-white hover:bg-purple-600 flex items-center justify-center text-lg"
                :to='{name:"Members", params:{showModalProp:true}}'
            >
                {{ $t('dashboardNewMemberButtonText') }}
            </router-link>
        </div>
        <!-- Statistics graph row -->
        <div
            class="col-span-12"
            :class="[userTypeStaff ? 'xl:col-span-9' : 'xl:col-span-8']"
        >
            <div class="bg-white rounded-lg shadow mb-30 p-20">
                <div class="mb-20 flex flex-col md:flex-row md:justify-between md:items-center">
                    <p class="text-2xl opacity-85 tracking-tight text-gray-800 font-bold font-titillium-bold mb-20 md:mb-30">
                        {{$t('dashboardCasesGraphTitle')}}
                    </p>
                    <div class="flex items-center mb-20">
                        <Button
                            v-on:btnClick="dailyGraph"
                            class="filter-case w-85 button font-bold rounded-r-none focus:outline-none hover:bg-purple-500 hover:text-white"
                            role="button"
                            :text="$t('dashboardGraphButtonDaily')"
                            :class="{'active_filter': graphState === 'daily'}"
                        />
                        <Button
                            v-on:btnClick="monthlyGraph"
                            class="filter-case w-85 button font-bold rounded-r-none rounded-l-none focus:outline-none hover:bg-purple-500 hover:text-white"
                            role="button"
                            :text="$t('dashboardGraphButtonMonthly')"
                            :class="{'active_filter': graphState === 'monthly'}"
                        />
                        <Button
                            v-on:btnClick="yearlyGraph"
                            class="filter-case w-85 button font-bold rounded-l-none focus:outline-none hover:bg-purple-500 hover:text-white"
                            role="button"
                            :text="$t('dashboardGraphButtonYearly')"
                            :class="{'active_filter': graphState === 'yearly'}"
                        />
                    </div>
                </div>
                <LineChart
                    ref="chart"
                    :chartData="datacollection"
                    :options="options"
                    :width=800
                    :height=420
                />
            </div>
            <div class="bg-white rounded-lg shadow mb-30">
                <!-- Search filter -->
                <div class="col-span-12 md:col-span-9 flex items-center relative p-20">
                    <div class="w-full h-full">
                        <input
                            class="focus:outline-none w-full rounded-lg bg-white pr-48"
                            :placeholder="$t('casesFilterPlaceholder')"
                            v-model="search"
                            type="search"
                            @input="onFieldEmpty"
                            @keyup.enter="searchCases"
                        />
                        <div class="absolute right-0 top-0 bg-purple-500 flex items-center search-btn">
                            <img
                                :src="require('@/assets/images/icons/search_white.svg')"
                                alt="searchIcon"
                                width="20"
                                @keyup.enter="searchCases"
                                @click="searchCases"
                            >
                        </div>
                    </div>
                </div>
            </div>
            <!-- Buttons for the all cases page -->
            <div
                v-if="showTable"
                class="bg-white rounded-lg shadow"
                :class="{'mb-30  xl:mb-0': userTypeManager}"
            >
                <div class="flex flex-col md:flex-row md:justify-between md:items-center p-20">
                    <p
                        v-if="searchResults.length"
                        class="text-2xl text-gray-800 opacity-85 tracking-tight font-bold font-titillium-bold mb-20 md:mb-0"
                    >
                        {{ $t('searchResultText') }}
                    </p>
                    <p
                        v-else
                        class="text-2xl text-gray-800 opacity-85 tracking-tight font-bold font-titillium-bold mb-20 md:mb-0"
                    >
                        {{userTypeManager ? $t('dashboardLastCasesTableTitleManager') : $t('dashboardLastCasesTableTitleStaff')}}
                    </p>
                    <router-link
                        class="button bg-purple-500 w-150 text-white hover:bg-purple-600 flex items-center justify-center font-bold text-base"
                        :to='{name:"Cases"}'
                    >
                        {{ $t('dashboardLastCasesAllCasesButtonText') }}
                    </router-link>
                </div>
                <!-- Cases info table -->
                <div>
                    <v-table
                        class="table"
                        :data="(((search === '') || (searchResults.length === 0)) ? lastModifiedCases : searchResults)"
                        :hideSortIcons="true"
                        :filters="filters"
                    >
                        <!-- Cases columns name -->
                        <thead
                            class="thead"
                            slot="head"
                        >
                            <!-- Case number column -->
                            <th class="w-85" style="padding-left: 20px;">#</th>
                            <!-- Victim name column -->
                            <th>
                                <v-th
                                    class="flex items-center justify-between flex-row-reverse th"
                                    sortKey="victim.first_name"
                                >
                                    {{ $t('casesTabelColumn_1') }}
                                </v-th>
                            </th>
                            <!-- Case status column -->
                            <th>
                                <v-th
                                    class="flex items-center justify-between flex-row-reverse th"
                                    sortKey="status"
                                >
                                    {{ $t('casesTabelColumn_3') }}
                                </v-th>
                            </th>
                            <!-- Case modified column -->
                            <th>
                                <v-th
                                    class="flex default-arrows items-center justify-between flex-row-reverse th"
                                    sortKey="modified_reversed"
                                    defaultSort="desc"
                                >
                                    {{ $t('casesTabelColumn_4') }}
                                </v-th>
                            </th>
                            <!-- Case registerd column -->
                            <th>
                                <v-th
                                    class="flex default-arrows items-center justify-between flex-row-reverse th"
                                    sortKey="registered_reversed"
                                >
                                    {{ $t('casesTabelColumn_5') }}
                                </v-th>
                            </th>
                        </thead>
                        <!-- Cases info rows -->
                        <tbody
                            id="table_body"
                            class="tbody"
                            slot="body"
                            slot-scope="{displayData}"
                        >
                            <!-- Table row -->
                            <tr
                                class="border-b hover:bg-gray-50"
                                v-for="cases in displayData"
                                :key="cases.id"
                            >
                                <!-- Case number row -->
                                <td class="text-purple-600 font-titillium-bold font-bold">
                                    <router-link :to="{ name: 'CaseDetails', params: { slug: `${cases.id}`} }">
                                        <div class="flex items-center">
                                            <span class="th lg:hidden mr-7">#:</span>
                                            {{cases.id}}
                                        </div>
                                    </router-link>
                                </td>
                                <!-- Case victim name row -->
                                <td class="text-gray-800 font-titillium-bold font-bold">
                                    <router-link :to="{ name: 'CaseDetails', params: { slug: `${cases.id}`} }">
                                        <div class="flex items-center">
                                            <span class="th lg:hidden mr-7">{{ $t('casesTabelColumn_1') }}:</span>
                                            <p>{{cases.victim.first_name}} {{cases.victim.last_name}}</p>
                                        </div>
                                    </router-link>
                                </td>
                                <!-- Case status row -->
                                <td>
                                    <div class="flex items-center">
                                        <span class="th lg:hidden mr-7">{{ $t('casesTabelColumn_2') }}:</span>
                                        <div
                                            class="text-center rounded-full w-85 py-4 small"
                                            :class="bindCaseStatus(cases.status.toLowerCase())"
                                        >
                                            <span v-if="cases.status === 'draft'">{{ $t('tableStatusDraft') }}</span>
                                            <span v-if="cases.status === 'registered'">{{ $t('tableStatusRegistered') }}</span>
                                            <span v-if="cases.status === 'in progress'">{{ $t('tableStatusInProgress') }}</span>
                                            <span v-if="cases.status === 'archived'">{{ $t('tableStatusInArchived') }}</span>
                                        </div>
                                    </div>
                                </td>
                                <!-- Case modified row -->
                                <td class="small text-gray-600">
                                    <div class="flex items-center">
                                        <span class="th lg:hidden mr-7">{{ $t('casesTabelColumn_3') }}:</span>
                                        {{ cases.modified }}
                                    </div>
                                </td>
                                <!-- Case registered row -->
                                <td class="small text-gray-600">
                                    <div class="flex items-center">
                                        <span class="th lg:hidden mr-7">{{ $t('casesTabelColumn_4') }}:</span>
                                        {{ cases.registered }}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </v-table>
                </div>
            </div>
            <div
                v-else
                class="bg-white rounded-lg shadow mb-30 p-20"
            >
                <p class="text-2xl opacity-85 tracking-tight text-gray-800 font-bold font-titillium-bold mb-20 md:mb-0">{{$t('dashboardNoResults')}}</p>
            </div>
        </div>
        <!-- Right part of the page with members and requests -->
        <div
            v-if="userTypeManager"
            class="col-span-12 xl:col-span-4"
        >
            <div class="bg-white rounded-lg shadow mb-30 pb-30">
                <p class="text-2xl opacity-85 tracking-tight text-gray-800 font-bold font-titillium-bold mb-20 pt-20 px-20">
                    {{$t('dashboardRecentlyActivatedMembersTitle')}}
                </p>
                <div
                    :key="index"
                    v-for="(member, index) in activeMembers"
                >
                    <div class="flex items-center px-20 justify-between">
                        <div class="flex items-center">
                            <p class="w-36 h-36 flex items-center justify-center ml-4 rounded-full bg-purple-50 text-purple-600 font-titillium-bold font-bold mr-10">
                                {{member.acronym}}
                            </p>
                            <p class="text-base font-titillium-bold text-gray-900 font-bold">{{member.name}}</p>
                        </div>
                        <p class="text-base font-titillium-bold font-bold text-indigo-700">{{member.cases_number}} <span class="text-gray-500 font-titillium-normal font-normal">{{member.cases_number === 1 ? $t('dashboardRecentlyActivatedMembersCase') : $t('dashboardRecentlyActivatedMembersCases')}}</span></p>
                    </div>
                    <hr
                        v-if="index != activeMembers.length -1"
                        class="my-16"
                    >
                </div>
                <router-link
                    class="mt-30 ml-20 button bg-purple-500 w-150 text-white hover:bg-purple-600 flex items-center justify-center font-bold text-base"
                    :to='{name:"Members"}'
                >
                    {{ $t('dashboardRecentlyActivatedMembersAllMembersButtonText') }}
                </router-link>
            </div>
            <div class="bg-white rounded-lg shadow p-20">
                <p class="text-2xl opacity-85 tracking-tight text-gray-800 font-bold font-titillium-bold">
                    {{$t('dashboardRecentRequestsTitle')}}
                </p>
                <div v-if="lastRequestedServices.length">
                    <div
                        :key="index"
                        v-for="(service, index) in lastRequestedServices"
                    >
                        <div class="flex items-center justify-between my-20">
                            <div class="flex items-center">
                                <p class="text-base font-titillium-bold font-bold">{{service.name}}</p>
                            </div>
                            <p class="text-base font-titillium-bold font-bold text-indigo-700">{{service.total}} <span class="text-gray-500 font-titillium-normal font-normal">{{service.measurement_unit}}</span></p>
                        </div>
                        <hr
                            v-if="index != lastRequestedServices.length -1"
                            class="my-16"
                        >
                    </div>
                </div>
                <p
                    class="my-20 opacity-85 tracking-tight font-titillium-normal text-gray-900"
                    v-else
                >
                    {{$t('dashboardRecentRequestsNoRequests')}}
                </p>
                <router-link
                    class="button w-180 bg-purple-500 text-white hover:bg-purple-600 flex items-center justify-center font-bold text-base"
                    :to='{name:"Services"}'
                >
                    {{ $t('dashboardRecentRequestsAllResourcesButtonText') }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import Button from '@/components/buttons/Button'
import LineChart from '@/components/charts/LineChart'
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'Dashboard',
    components: {
        Button,
        LineChart
    },
    data () {
        return {
            gradient: '',
            search: '',
            showTable: true,
            graphState: 'monthly',
            datacollection: {},
            options: {
                scales: {
                    xAxes: [{
                        gridLines: {
                            display: false,
                            drawBorder: false
                        },
                        ticks: {
                            padding: 10,
                            fontColor: '#6B7280'
                        }
                    }],
                    yAxes: [{
                        gridLines: {
                            drawBorder: false
                        },
                        ticks: {
                            beginAtZero: true,
                            padding: 10,
                            fontColor: '#6B7280'
                        }
                    }]
                },
                legend: {
                    display: false
                },
                animation: {
                    duration: 0
                }
            },
            // Table filters
            filters: {
                name: { value: '', keys: ['name', 'id'] },
                inProgress: { value: '', keys: ['status'] },
                registered: { value: '', keys: ['status'] }
            }
        }
    },
    computed: {
        ...mapGetters(['chartData', 'cases', 'lastModifiedCases', 'inProgressCases', 'registeredCases', 'reportedCases', 'activeMembers', 'lastRequestedServices', 'token', 'userTypeManager', 'userTypeStaff', 'searchResults'])
    },
    methods: {
        ...mapActions(['getDashboardChartData', 'getMembers', 'getActiveMembers', 'getLastModifiedCases', 'getCaseInProgress', 'getRegisteredCases', 'getReportedCases', 'getRequestedLastServices', 'getSearchedCases']),

        /** Function to populate the 3 graphs with data at a click event. */
        monthlyGraph () {
            this.graphState = 'monthly'
            this.gradientBackground()

            this.datacollection = {
                labels: this.chartData.monthly.labels,
                datasets: [{
                    data: this.chartData.monthly.values,
                    borderColor: '#8C52FF',
                    color: [
                        '#8C52FF'
                    ],
                    borderWidth: 3,
                    fill: true,
                    backgroundColor: this.gradient,
                    pointRadius: 6,
                    pointBackgroundColor: '#FFF',
                    tickBorderDash: 10
                }]
            }
        },
        yearlyGraph () {
            this.graphState = 'yearly'
            this.gradientBackground()

            this.datacollection = {
                labels: this.chartData.yearly.labels,
                datasets: [{
                    data: this.chartData.yearly.values,
                    borderColor: '#8C52FF',
                    color: [
                        '#8C52FF'
                    ],
                    borderWidth: 3,
                    fill: true,
                    backgroundColor: this.gradient,
                    pointRadius: 6,
                    pointBackgroundColor: '#FFF',
                    tickBorderDash: 10
                }]
            }
        },
        dailyGraph () {
            this.graphState = 'daily'
            this.gradientBackground()

            this.datacollection = {
                labels: this.chartData.daily.labels,
                datasets: [{
                    data: this.chartData.daily.values,
                    borderColor: '#8C52FF',
                    color: [
                        '#8C52FF'
                    ],
                    borderWidth: 3,
                    fill: true,
                    backgroundColor: this.gradient,
                    pointRadius: 6,
                    pointBackgroundColor: '#FFF',
                    tickBorderDash: 10
                }]
            }
        },
        // Return values for sorting and filtering
        name (cases) {
            // return `${cases.victim.firstname} ${cases.victim.lastname}`

        },
        status (cases) {
            return cases.status
        },
        // Add css class based on database value for case status
        bindCaseStatus (status) {
            if (status === 'registered') {
                return 'bg-yellow-500 text-purple-600'
            } else if (status === 'in progress') {
                return 'bg-purple-500 text-white'
            } else if (status === 'archived') {
                return 'bg-gray-500 text-white'
            } else {
                return 'bg-gray-200 text-gray-800'
            }
        },

        /** Filter cases method. */
        searchCases () {
            this.getSearchedCases({
                token: this.token,
                query: this.search
            }).then(response => {
                if ((this.search !== '') && (this.searchResults.length === 0)) {
                    this.showTable = false
                }
            })
        },

        /** If search field is empty show table. */
        onFieldEmpty () {
            if (this.search === '') {
                this.showTable = true
                this.searchResults.length = 0
            }
        },

        gradientBackground () {
            var ctx = this.$refs.chart.$refs.canvas.getContext('2d')
            this.gradient = ctx.createLinearGradient(0, 0, 0, 400)
            this.gradient.addColorStop(0, 'rgba(181, 143, 255, 0.6)')
            this.gradient.addColorStop(1, 'rgba(201, 174, 255, 0)')
        }
    },
    async created () {
        if (this.userTypeManager) {
            await this.getActiveMembers({ token: this.token })
            await this.getRequestedLastServices({ token: this.token })
        }
        await this.getLastModifiedCases({ token: this.token })
        await this.getCaseInProgress({ token: this.token })
        await this.getRegisteredCases({ token: this.token })
        await this.getReportedCases({ token: this.token })

        /** Extract dashboard data and populate the initial monthly graph */
        await this.getDashboardChartData({ token: this.token })
            .then(response => {
                this.graphState = 'monthly'
                this.gradientBackground()

                this.datacollection = {
                    labels: this.chartData.monthly.labels,
                    datasets: [{
                        data: this.chartData.monthly.values,
                        borderColor: '#8C52FF',
                        color: [
                            '#8C52FF'
                        ],
                        borderWidth: 3,
                        fill: true,
                        backgroundColor: this.gradient,
                        pointRadius: 6,
                        pointBackgroundColor: '#FFF',
                        tickBorderDash: 10
                    }]
                }
            })
    }
}
</script>
